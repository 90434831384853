
const { NODE_ENV } = process.env;
const { hostname } = window.location;

const servers = {
  local: "http://localhost:3012",
  customDev: "https://react.customdev.solutions:3012",
  live: "https://wrightcoacademy.com:3012",
};

var URL;
if (NODE_ENV === "production" && hostname.includes("react.customdev.solutions"))
  URL = servers.customDev;
else if (NODE_ENV === "production" && hostname.includes("wrightcoacademy.com") ) URL = servers.live;
else URL = servers.local;

export const SITE_NAME = "WrightCo Super Admin"

export const BASE_URL = `${URL}/api`;
export const UPLOAD_URL = `${URL}/Uploads/`;
export const UPLOAD_URL2 = `${URL}/`;
export const SOCKET_URL = `${URL}`;

// export const BASE_URL = "https://react.customdev.solutions:3012/api"
// export const UPLOAD_URL = "https://react.customdev.solutions:3012/Uploads/"
// export const UPLOAD_URL2 = "https://react.customdev.solutions:3012/"
// export const SOCKET_URL = "https://react.customdev.solutions:3012"

// export const BASE_URL = "http://localhost:3012/api"
// export const UPLOAD_URL = "http://localhost:3012/Uploads/"
// export const UPLOAD_URL2 = "http://localhost:3012/"
// export const SOCKET_URL = "http://localhost:3012"

// export const BASE_URL = "https://secure.demo243.webhostlabs.net:3002/api/"
// export const UPLOAD_URL = "https://secure.demo243.webhostlabs.net:3002/Uploads/"




export const AUTH = {
    signin: "/admin/auth/signinSuperAdmin",
    logout: "/auth/logout",
    emailCode:"/admin/auth/emailVerificationCode",
    verifyCode:"/admin/auth/verifyRecoverCode",
    resetPassword:"/admin/auth/resetPassword",
  };

  
  export const NEWS = {
    getNewsFeed : "/post/getNewsFeeds",
    getUserPosts:"/post/getUserPosts/",
    likePost : "/post/likePost",
    lovePost: "/post/lovePost",
    commentPost:"/post/comment/",
    addPost:"/post/addPost"
}


export const RATES = {
  getMyRates: "/rates/getMyRates",
  getCoachRates:"/rates/getCoachRates/",
  setRates : "/rates/setRates"
}

export const COMISSSION = {
  getComission : "/comission/getComission",
  addComission:"/comission/addComission"
}

export const REVIEWS={
  getAll:"/review/getAllReviewsByCoachId/",
  getCoachRatings:"/review/getCoachRatings/"
}

export const SCHEDULE = {
  addSchedule: "/schedule/addSchedule",
  getMySchedule:"/schedule/getMySchedule",
  getScheduleByCoachId:"/schedule/getScheduleByCoachId/"
}

export const GALLERY = {
  addGallery:"/gallery/addGallery",
  getMyGallery: "/gallery/getMyGallery/",
  getAllGallery:"/gallery/getAllGallery"
}

export const CONTEST = {
  addContest:"/admin/contests/createContest",
  getAllContests:"/admin/contests/getAllContests",
  getAllContestEntries:"/admin/entries/getAllContestEntries/",
  getOne:"/admin/contests/getContestById/",
  updateContest:"/admin/contests/updateContest/",
  deleteContest:"/admin/contests/deleteContest/",
  selectWinner:"/admin/contests/selectWinner"
}

  export const ADMIN = {
    updateProfile: "/profile/updateProfile",
  };

  export const USERS = {
    getAllStudents: "/admin/user/getAllStudents",
    getAllAdmins: "/admin/user/getAllAdmins",
    getStudentById: "/admin/user/getStudentById/",
    toggleStatus: "/admin/user/toggleStatus/",
  };

  export const LESSON = {
    getAllUpcomingLessons: "/admin/lesson/getAllUpcomingLessons",
    getAllCompletedLessons: "/admin/lesson/getAllCompletedLessons",
    getAllPendingLessons: "/admin/lesson/getAllPendingLessons",
    getAllLiveLessons: "/admin/lesson/getAllLiveLessons",
    getAllRejectedLessons: "/admin/lesson/getAllRejectedLessons",
    getAllMissedLessons: "/admin/lesson/getAllMissedLessons",
    getLessonById: "/admin/lesson/getLessonById/",
    deleteLesson: "/admin/lesson/deleteLesson/",
    getLessonsByMonth:"/admin/lesson/getLessonsByMonth"
  };


  export const COACH = {
    getAllTutorAndCoaches: "/admin/user/getAllTutorAndCoaches",
    getCoachById: "/admin/user/getCoachById/",
    toggleStatus: "/admin/user/toggleStatus/",
  };
  export const SERVICE_PROVIDERS = {
    get: "/users/admin/serviceProvider",
    getOne: "/users/getSpById/",
    toggleStatus: "/users/toggleActiveInActive",
  };

  export const CATEGORIES = {
    getAllcategories: "/category/getAllcategories",
    addCategory: "/category/addCategory",
    updateCategory: "/category/updateCategory/",
    toggleStatus: "/category/toggleStatus/",
    edit:"/category/edit/",
  };

  export const LECTURES = {
    getAllLectures: "/lecture/getAllLectures",
    getLectureById:"/lecture/getLectureById/",
    updateLecture:"/lecture/updateLecture/",
    addLecture:"/lecture/addLecture"
  };

  export const FEEDBACK = {
    get: "/contact",
    getOne: "/contact/feedbackById/",
  };


  export const COURSE = {
    addCourse:"/course/addCourse",
    getAllCourses: "/course/getAllCourses",
    getCourseById: "/course/getCourseById/",
    updateCourse:"/course/updateCourse/"
  };

  export const ORGANIZATION = {
    addOrganization:"/organization/addOrganization",
    getAllOrganizations: "/organization/getAllOrganizations",
    getAllActiveOrganizations: "/organization/getAllActiveOrganizations",
    getOrganizationById: "/organization/getOrganizationById/",
    toggleStatus:"/organization/toggleStatus/",
    updateOrganization:"/organization/updateOrganization/",
    deleteOrganization:"/organization/deleteOrganization/"
  };



  export const SUBSCRIPTION = {
    get: "/plan/getAllPlans",
    create:"/plan/addPlan",
    getOne: "/plan/getPlanById/",
    edit: "/plan/editPlan/",
    delete: "/plan/deletePlan/",
  };


  export const PAYMENT = {
    get: "/payment/getAllPayments",
    getOne: "/payment/",
    getAllSubscriptionPayments:"/admin/payment/getAllSubscriptionPayments",
    getAllContestPayments:"/admin/payment/getAllContestPayments"
  };

  export const NOTIFICATION = {
    get: "/notification/getAllNotifications",
    getOne: "/notification/getNotificationById/",
    create: "/notification/createAlertOrAnnoucement",
    sendPushNotification:"/notification/sendPushNotification",
    getAllAdminNotifications:"/notification/getAllAdminNotifications",
    getUnreadAdminNotifications:"/notification/getUnreadAdminNotifications",
    toggleNotification:"/notification/toggleNotification/"
  };
  
  export const QUERY = {
    get: "/query/getAllQueries",
    getOne: "/query/getQueryById/",
  };

  export const REQUEST = {
    get: "/request/getAllRequests",
    getOne: "/request/getRequestById/",
  };

  export const ARTICLE = {
    get: "/article/getAllArticles",
    getOne: "/article/getArticleById/",
    add: "/article/addArticle",
    edit: "/article/updateArticle/",
    delete:"/article/deleteArticle/",
  };


  export const ARTICLECATEGORIES = {
    get: "/articleCategory/getAllArticleCategories",
    getOne: "/articleCategory/getArticleCategoryById/",
    add: "/articleCategory/addArticleCategory",
    edit: "/articleCategory/updateArticleCategory/",
    delete:"/articleCategory/deleteArticelCategory/",
  };


  export const CONTENT_TYPE = {
    JSON: "application/json",
    FORM_DATA: "multipart/form-data",
}