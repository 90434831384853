import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Input,
  Button,
  ColorPicker,
  Layout,
  Avatar,
  Upload,
  Table,
  Select,
  Image,
  Modal,
  DatePicker,
  Skeleton,
} from "antd";
import dayjs from "dayjs";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { UPLOAD_URL, ORGANIZATION,USERS,CONTENT_TYPE } from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { TbCameraPlus } from "react-icons/tb";
const { Option } = Select;



function OrganizationDetails() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [organization, setOrganization] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false)
  const [imageNew, setImageNew] = useState();
  const [admins, setAdmins] = useState([]);
  const [colors, setColors] = useState([]);


  useEffect(() => {
    getOrganization();
    getAdmins();
  }, []);

  const add = (item) => {
    let _colors = [...colors]
    _colors.push(item);
    setColors(_colors)
    }
    
    
    const changeColor = (item,index) => {
      let _colors = [...colors]
      _colors[index] = item;
      setColors(_colors)
      }
    
    
    const remove = (item,index) => {
     let _colors = [...colors]
      _colors.splice(index,1)
      setColors(_colors)
      }



  const getOrganization = async () => {
    setLoading(true);
    const res = await Get(
      `${ORGANIZATION.getOrganizationById}${id}`,
      token
    );

    console.log("<<<<",organization)

    setOrganization(res.data.organization);
    setColors(res.data.organization.colors.toString().split(","))
    setLoading(false);
  };

  const getAdmins = async () => {
    setLoading(true);
    const res = await Get(
      `${USERS.getAllAdmins}`,
      token,{
        limit:"100"
      }
    );

    console.log("<<<<>>>>>",res)

    setAdmins(res.data.docs);
    setLoading(false);
  };

  const handleStatus = async () => {
    try {
      const response = await Get(
        ORGANIZATION.toggleStatus + "/" + organization._id,
        token,
        {}
      );
      const newUser = { ...organization };

      newUser.isActive = !organization.isActive;
      setModalOpen(false);
      setOrganization(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = (values) => {

    const formObject = new FormData();

    if (imageNew) {
      formObject.append("image", values.image.fileList[0].originFileObj);
    }

    formObject.append("colors", colors);

    for (const key in values) {
      if (key !== "image") {
        const item = values[key];
        formObject.append(key, item);
      }
    }

    Post(ORGANIZATION.updateOrganization+id, formObject, token, null, CONTENT_TYPE.FORM_DATA)
      .then((response) => {
        if (response?.data?.status) {
          console.log(response?.data);
  
          swal("Success!", "Organization Updated Successfully", "success");
          getOrganization()

          setEditMode(false);
          setImageNew();
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };



  return (
    <Layout className="configuration">
      <div className="boxDetails2">
        <Row style={{ padding: "10px 20px" }}>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaArrowLeft
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onClick={() => navigate(-1)}
            />
            &emsp;
            <h1 className="pageTitle" style={{ margin: 0 }}>
              {editMode ? "Edit Organization Details" : "View Organization Details"}
            </h1>
          </Col>
         
        </Row>
        <br />

        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Skeleton active  paragraph={{ rows:10 }} />
          </div>
        )
            }

            {!loading && organization && (<>


              <Form
          layout="vertical"
          name="basic"
          className="contactForm"
          labelCol={{
            span: 0,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row style={{ padding: "20px" }}>
            <Col xs={24} md={18}>
              <Row>
                {editMode ? (
                  <Form.Item name="image">
                    <Upload
                      name="image"
                      showUploadList={false}
                      style={{ position: "relative" }}
                      beforeUpload={(file) => {
                        setImageNew(URL.createObjectURL(file));
                        return false;
                      }}
                    >
                      {" "}
                      <div
                        style={{
                          padding: "8px",
                          position: "absolute",
                          right: -10,
                          zIndex: 2,
                          bottom: 40,
                          backgroundColor: "#00a79d",
                          display: "flex",
                          maxWidth: "fit-content",
                          color: "white",
                          borderRadius: "20px",
                        }}
                      >
                        <TbCameraPlus />
                      </div>{" "}

<Image
preview={false}
                  src={imageNew
                    ? imageNew
                    : !organization?.logo
                    ? "/images/avatar.png"
                    : UPLOAD_URL + "/" + organization?.logo }
                  height={300}
                  width={500}
                  style={{ borderRadius: "10px", objectFit: "cover" }}
                />
                    </Upload>
                  </Form.Item>
                ) : (
                  

                <div style={{height:300,width:500, padding:20, display:'flex', textAlign:"center", flexDirection:"column",justifyContent:"center", alignItems:"center", backgroundColor:"#fafafa", border:"2px dashed #dadada", borderRadius:'10px'}}><Image
                src={!organization.logo ? "/images/avatar.png" : UPLOAD_URL + "/" + organization.logo }
                style={{ borderRadius: "10px", objectFit: "contain",maxWidth:450, maxHeight:250 }}
              /></div>
                )}
              </Row>

              <br />
              <br />

              {editMode ? (
                <>
           

                  <Row gutter={20}>
                
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Title :
                  </Typography.Title>
               
                  <Form.Item
                    name="title"
                    initialValue={organization?.title}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input organization title",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Title"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                   Description:
                  </Typography.Title>
               
                  <Form.Item
                    name="description"
                    initialValue={organization?.description}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Organization description",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Description"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                   Address:
                  </Typography.Title>
               
                  <Form.Item
                    name="address"
                    initialValue={organization?.address}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input Organization address",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Address"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Admin :
                  </Typography.Title>
               
                  <Form.Item
                    name="admin"
                    initialValue={organization?.admin._id}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select organization admin",
                      },
                    ]}
                  >
                     <Select
       placeholder="Select Category"
       className="signupSelectBox"
    >
      {admins.map((item,index) => {
        return(<Option value={item._id}>{item.firstName + " " + item.lastName}</Option>)
      })}

    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                   Email:
                  </Typography.Title>
               
                  <Form.Item
                    name="email"
                    initialValue={organization?.email}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input organization email",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Duration"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Phone :
                  </Typography.Title>
               
                  <Form.Item
                    name="phone"
                    initialValue={organization?.phone}
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please input organization phone",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Phone Number"
                      className="signupFormInput"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col
                  xs={24}
                  sm={24}
                  style={{ display: "flex", alignItems: "flex-start",flexDirection:'column' }}
                >
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "left",
                      marginBottom: 20,
                    }}
                  >
                    Colors :
                  </Typography.Title>
               
                  <div
        style={{
          display: 'flex',
          columnGap: "10px",
          flexDirection: 'row',
        }}
      >
        {colors.map((item,index) => (
          
              <div   style={{
          display: 'flex',
          columnGap: "10px",
          flexDirection: 'row',
          justifyContent:'center',
          alignItems:'center',
          border:"1px solid #dadada",
          padding:'0px 5px',
          borderRadius:"10px"
        }}>
               <ColorPicker defaultFormat	="hex" value={item} size="large" showText style={{border:'none'}} onChange={(color,hex) => changeColor(hex,index)}
/>
               <Button type="none" style={{padding:"2px"}}  onClick={() => {
                  remove(item,index);
                }}>X            </Button>
              
              </div>
            
        
        ))}

        <Button type="dashed"  size="large" onClick={() => add("#1677FF")} block>
          + Add Item
        </Button>
      </div>
                </Col>
             
              </Row>
                
                 
              

                
                </>
              ) : (
                <>
                     <Row style={{ padding: "10px" }}>
              <Col xs={24} md={12}>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom:10
                  }}
                >
                  Title{" "}
                </h5>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    color: "#7a7e7f",
                    fontWeight: "normal",
                  }}
                >
                  {organization?.title}
                </h5>
              </Col>
              <Col xs={24} md={12}>
              <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom:10
                  }}
                >
                  Description{" "}
                </h5>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    color: "#7a7e7f",
                    fontWeight: "normal",
                  }}
                >
                  {organization?.description}
                </h5>
              </Col>
            </Row>

            <br/>
            <Row style={{ padding: "10px" }}>
    
              <Col xs={24} md={12}>
              <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom:10
                  }}
                >
                  Address
                </h5>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    color: "#7a7e7f",
                    fontWeight: "normal",
                  }}
                >
                  {organization?.address}
                </h5>
              </Col>
              <Col xs={24} md={12}>
              <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom:10
                  }}
                >
                  Admin
                </h5>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    color: "#7a7e7f",
                    fontWeight: "normal",
                  }}
                >
                  {organization?.admin?.firstName + " " + organization?.admin?.lastName}
                </h5>
              </Col>
            </Row>
            <br/>

            <Row style={{ padding: "10px" }}>
              <Col xs={24} md={12}>
              <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom:10
                  }}
                >
                  Email{" "}
                </h5>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    color: "#7a7e7f",
                    fontWeight: "normal",
                  }}
                >
                  {organization?.email  }
                  
                </h5>
              </Col>
              <Col xs={24} md={12}>
              <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom:10
                  }}
                >
                  Phone{" "}
                </h5>
                <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    color: "#7a7e7f",
                    fontWeight: "normal",
                  }}
                >
                  {organization?.phone}
                </h5>
              </Col>
            
            </Row>
            <br/>
            <Row style={{ padding: "10px" }}>

              <Col xs={24} md={24}>
              <h5
                  style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginBottom:10
                  }}
                >
                  Colors{" "}
                </h5>
                {organization?.colors.length > 0 && organization?.colors.toString().split(",").map(item => { 
                  console.log(item)
                  return( <h5
                  style={{
                    display: "flex",
                    justifyContent:"flex-start",
                    alignItems:"center",
                    flexDirection:'row',
                    fontSize: 16,
                    color: "#7a7e7f",
                    fontWeight: "normal",
                    margin:"10px 0px"
                  }}
                >
               <div style={{display: "inline-block",width:"20px", height:'20px' , backgroundColor:item}} />&nbsp;{item} &emsp;
                  
                </h5>)})}
               
              </Col>

            </Row>
                </>
              )}

              <Row style={{ marginTop: 30 }}>
                {editMode && (
                  <>
                    {" "}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="loginButton"
                    >
                      Save Changes
                    </Button>
                    &emsp;
                    <Button
                      className="fontFamily1"
                      style={{
                        marginTop: "0px",
                        padding: "10px 30px",
                        cursor: "pointer",
                        color: "black",
                        borderRadius: "5px",
                        height: "auto",
                        border: "1px solid #203657",
                        fontWeight: "bold",
                      }}
                      ghost
                      size="large"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditMode(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
                {!editMode && (
                  <Row justify={"center"}>
                    <Col style={{ textAlign: "center" }}>
                      <Button
                        type="primary"
                        htmlType="button"
                        className="loginButton"
                        onClick={() => setEditMode(true)}
                      >
                        Edit Organization
                      </Button>
                    </Col>
                  </Row>
                )}
              </Row>
            </Col>
          </Row>
        </Form>

        
            </>)}


        
        <br />
        <br />
      </div>

      <Modal
        open={modalOpen}
        onOk={() => handleStatus()}
        onCancel={() => setModalOpen(false)}
        okText="Yes"
        className="StyledModal"
        style={{
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
        }}
        cancelText="No"
        cancelButtonProps={{
          style: {
            border: "2px solid #2484c6",
            color: "#2484c6",
            height: "auto",
            padding: "6px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            marginTop: "15px",
          },
        }}
        okButtonProps={{
          style: {
            backgroundColor: "#2484c6",
            color: "white",
            marginTop: "15px",
            height: "auto",
            padding: "5px 35px",
            borderRadius: "50px",
            fontSize: "16px",
            border: "2px solid #2484c6",
          },
        }}
      >
        <Image
          src="../images/question.png"
          preview={false}
          width={100}
          height={120}
        />
        <Typography.Title level={4} style={{ fontSize: "25px" }}>
          {organization?.isActive ? "Deactivate" : "Activate"}
        </Typography.Title>
        <Typography.Text style={{ fontSize: 16 }}>
        Do You Want To  {organization?.isActive ? "Deactivate" : "Activate"} This User?
        </Typography.Text>
      </Modal>


      <br />
      <br />
    </Layout>
  );
}
export default OrganizationDetails;
